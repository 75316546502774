// counterData.js

const counterData = {
    targetInteractions: 10,
    targetTransactions: 10,
    targetRating: 4.5,
    targetProjects: 500,
  };
  
  export default counterData;
  