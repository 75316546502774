import React from 'react';
import ContactComponent from '../../components/Contact/index';

function MobileContactPage() {
  return (
    <div>
      <ContactComponent />
    </div>
  );
}

export default MobileContactPage;
