import React, { useState, useEffect } from "react";
import "./Header.css";
import headerMenu from "../../../../../data/commonData/headerData/headerData";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [currentNav, setCurrentNav] = useState(null);
  const [navsVisited, setNavsVisited] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const careersBaseRoute = "/careers";
  const productsBaseRoute = "/products";
  const [isLoggedIn] = useState(true); // Define isLoggedIn state
  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);

  const isAllowedCareersRoute = location.pathname.startsWith(careersBaseRoute);
  const isAllowedProductsRoute =
    location.pathname.startsWith(productsBaseRoute);

  const handleMouseEnter = (e, item) => {
    setCurrentNav(item.name.en);
    setNavsVisited({
      ...navsVisited,
      [item.name.en]: true,
    });
  };

  const handleMouseLeave = () => {
    setCurrentNav(null);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openProfileDropdown = () => {
    setProfileDropdownVisible(true);
  };

  const closeProfileDropdown = () => {
    setProfileDropdownVisible(false);
  };

  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <div className="logo">
        <img src="./logo.png" alt="Logo" />
      </div>

      <nav className="navbar">
        <ul className="nav__links">
          {headerMenu.map((item, index) => (
            <li
              key={index}
              data-expand={item.name.en}
              className={`nav--link ${
                currentNav === item.name.en || navsVisited[item.name.en]
                  ? "hover"
                  : ""
              }`}
              onMouseEnter={(e) => handleMouseEnter(e, item)}
              onMouseLeave={handleMouseLeave}
            >
              <a href={item.url}>{item.name.en}</a>
              {item.submenu && currentNav === item.name.en && (
                <ul
                  className={`submenu ${
                    currentNav === item.name.en ? "active" : ""
                  }`}
                >
                  <div className="submenu-list">
                    {item.submenu.map((subItem, subIndex) => (
                      <div key={subIndex} className="submenu-item-container">
                        <li>
                          <a href={subItem.url} className="submenu-link">
                            <div className="submenu-content">
                              {subItem.icon &&
                                React.createElement(subItem.icon, {
                                  className: "submenu-icon",
                                })}
                              <div className="submenu-text-content">
                                <span className="submenu-name">
                                  {subItem.name.en}
                                </span>
                                <span className="submenu-description">
                                  {subItem.description}
                                </span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </div>
                    ))}
                  </div>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>

        {!isAllowedCareersRoute && !isAllowedProductsRoute && (
          <a href="/get-started" className="header-btn">
            Get Started
          </a>
        )}

      {/* <div className="buttons">
        {isAllowedCareersRoute && (
          <a href="/login-careers" className="header-btn">
            Careers Login
          </a>
        )}
        {isAllowedProductsRoute && (
          <a href="/product-login" className="header-btn">
            Products Login
          </a>
        )}
        {!isAllowedCareersRoute && !isAllowedProductsRoute && (
          <a href="/get-started" className="header-btn">
            Get Started
          </a>
        )}
        {isLoggedIn && (
          <div
            className="header-profile"
            onMouseEnter={openProfileDropdown}
            onMouseLeave={closeProfileDropdown}
          >
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt="Profile"
              className="header-profile-image"
            />

            {isProfileDropdownVisible && (
              <ul className="profile-dropdown">
               
                <li>
                  <a href="/profile">Profile</a>
                </li>
                <li>
                  <button>Logout</button>
                </li>
              </ul>
            )}
          </div>
        )}
      </div> */}
    </header>
  );
};

export default Header;
