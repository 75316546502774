// src/apiConfig.js


export const API_URLS = {
    API_BASE_URL: 'http://localhost:8000',
    subscribe: `/api/subscribe`,
    contact: `/api/contact`,
    internApplication: `/internship/apply`,
    googleLogin: `/auth/google/login`, // Adjust the endpoint as needed
  };
  