import React from "react";
import Login from "../../../components/Authencation/Login/Login";
import './login.css'

function LoginPage() {
  return (
    <div className="comp">
      <Login />
    </div>
  );
}

export default LoginPage;
