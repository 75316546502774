import LandingImg from '../../image.jpg';
import DetailsImg from '../../image1.gif';



const voiceData = {
    LandingContent: {
        title: "Optimize Operations with vOICE",
        minititle: "Virtual Operations & Interactive Communication Engine",
        paragraph: "Experience the power of vOICE - a cutting-edge solution revolutionizing operations through virtual systems and interactive communication. Streamline processes, enhance productivity, and foster seamless communication across your organization.",
        buttonText: "Explore vOICE - Request a Demo",
        imageUrl: LandingImg,
        imageAlt: "vOICE - Virtual Operations and Communication",
        demoLink: "/contact",
    },
    DetailsContent: {
        heading: 'Transform Operations with vOICE: Your Virtual Engine',
        paragraph: 'Empower your organization with vOICE, an interactive communication engine designed to optimize operations. Leverage virtual systems and enhanced communication for efficient workflows and productivity.',
        features: [
            {
                title: 'VIRTUAL SYSTEMS',
                heading: 'Efficient Virtual Operations',
                paragraph: 'Implement virtual systems for automating tasks, managing processes, and optimizing resource utilization. Streamline operations and enhance efficiency with virtual assistance.',
                imageUrl: DetailsImg,
                imageAlt: 'Virtual Systems in vOICE',
            },
            {
                title: 'INTERACTIVE COMMUNICATION',
                heading: 'Seamless Communication Channels',
                paragraph: 'Facilitate seamless communication through interactive channels. Enable collaboration, meetings, and information sharing with integrated and user-friendly communication tools.',
                imageUrl: DetailsImg,
                imageAlt: 'Interactive Communication in vOICE',
            },
            {
                title: 'PRODUCTIVITY TOOLS',
                heading: 'Enhanced Productivity Solutions',
                paragraph: 'Leverage productivity tools and integrations to optimize workflows and streamline tasks. Empower teams with efficient tools to maximize output and efficiency.',
                imageUrl: DetailsImg,
                imageAlt: 'Productivity Tools in vOICE',
            },
            // Add more features specific to vOICE here...
        ],
    },
};

export default voiceData;
