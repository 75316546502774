// routeData.js


//product route
import siteData from './productData/productsPageData/site';
import coreData from './productData/productsPageData/core';
import cueData from './productData/productsPageData/cue';
import tradeData from './productData/productsPageData/trade';
import voiceData from './productData/productsPageData/voice';

//service route
import sdaasData from './serviceData/servicesPageData/sdaas';
import maasData from './serviceData/servicesPageData/maas';
import baasData from './serviceData/servicesPageData/baas';
import vmaasData from './serviceData/servicesPageData/vmaas';


const routeData = [
    { url: "/products/s.i.t.e", Data: siteData },
    { url: "/products/c.o.r.e", Data: coreData },
    { url: "/products/t.r.a.d.e", Data: tradeData },
    { url: "/products/c.u.e", Data: cueData },
    { url: "/products/v.o.i.c.e", Data: voiceData },
    { url: "/services/maas", Data: maasData },
    { url: "/services/baas", Data: baasData },
    { url: "/services/sdaas", Data: sdaasData },
    { url: "/services/vmaas", Data: vmaasData }
];

export default routeData;
