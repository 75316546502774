import React from "react";
import PlainLanding from "../../../components/Additional/PlainLanding/PlainLanding";
import LinkComponent from "../../../components/Additional/linkComponent/linkComponent";

function MobileLegalPage() {
  return (
    <div>
      <PlainLanding />
      <LinkComponent />
    </div>
  );
}

export default MobileLegalPage;
