import React from 'react';
import ContactComponent from '../../components/Contact/index';

function ContactPage() {
  return (
    <div>
      <ContactComponent />
    </div>
  );
}

export default ContactPage;
