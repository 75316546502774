// SubProSerComponentContent.js
import LandingImg from '../../image.jpg';
import DetailsImg from '../../image1.gif';




const siteData = {
 LandingContent : {
        title: "Empower Your Development and Publishing Efforts with S.I.T.E.",
        minititle: "Next-Level Flexibility and Efficiency for Developers and Publishers",
        paragraph: "Experience the future of web development and content publishing with S.I.T.E. - a comprehensive platform offering a developer-centric approach, customizable API, intuitive editor, and SEO optimization. Revolutionize your projects with multi-database support, GraphQL/RESTful options, and seamless content management. Explore the power of S.I.T.E. and elevate your digital presence today.",
        buttonText: "Experience the Future - Get A Demo",
        imageUrl: LandingImg, // Replace LandingImg with the actual URL of the image
        imageAlt: "Simplified Interface for Transformation and Enhancement",
        demoLink: "/contact",
    },
   DetailsContent : {
        heading: 'Unlock Innovative Capabilities with S.I.T.E.: A Unified AI-Powered Solution',
        paragraph:
          'Enhance user experience and streamline operations with cutting-edge AI capabilities integrated into S.I.T.E. A unified solution offering dynamic self-service, conversational support, and intelligent ticketing.',
        features: [
            {
              title: 'DEVELOPER-CENTRIC APPROACH',
              heading: 'API-Driven for Developer Needs',
              paragraph:
                'S.I.T.E. adopts an API-driven approach, prioritizing developer requirements. Seamlessly integrate with multiple databases, leverage GraphQL or RESTful support, and utilize JavaScript across the stack for enhanced flexibility and customization.',
              imageUrl: DetailsImg,
              imageAlt: 'API-Driven Development Image',
            },
            {
              title: 'FLEXIBLE CONTENT MANAGEMENT',
              heading: 'Dynamic Content Creation & Management',
              paragraph:
                'Effortlessly manage diverse content categories, formats, and flows. Utilize a user-friendly interface, media library, and SEO optimization tools for superior content handling. Customize and enhance your content with unique editing experiences and internationalization features.',
              imageUrl: DetailsImg,
              imageAlt: 'Flexible Content Management Image',
            },
            {
                title: 'MULTI-DATABASE SUPPORT',
                heading: 'Seamless Integration with Multiple Databases',
                paragraph:
                  'Effortlessly connect with various databases including SQLite, MySQL, Postgres, and MariaDB. Enjoy the flexibility to choose and utilize databases that suit your project needs.',
                imageUrl: DetailsImg, // Replace DetailsImg with the image URL
                imageAlt: 'Multi-Database Support Image',
              },
              {
                title: 'GRAPHQL AND RESTFUL SUPPORT',
                heading: 'Flexible API Consumption',
                paragraph:
                  'Enable your applications and devices to interact with S.I.T.E. using REST or GraphQL. Provide flexibility and efficiency in consuming data across different platforms and technologies.',
                imageUrl: DetailsImg, // Replace DetailsImg with the image URL
                imageAlt: 'GraphQL and RESTful Support Image',
              },{
                title: 'ROBUST SECURITY MEASURES',
                heading: 'Ensuring End-to-End Protection',
                paragraph:
                  'S.I.T.E. prioritizes security with robust authentication and permission protocols. Implement role-based access control to safeguard endpoints. Utilize JWT authentication for secure API access. Additionally, benefit from built-in security measures to protect against vulnerabilities and threats.',
                imageUrl: DetailsImg,
                imageAlt: 'Robust Security Measures Image',
              }
            // Add more features from the S.I.T.E. system here...
        ]
    }
    
};

export default siteData;
