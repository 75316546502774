// ChatBot.js

import React, { useState, useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "./ChatBot.css";
import ChatBotIcon from "./chatbot.png";
import ChatBotLogo from "./ChatBotLogo.png";
import popSound from "./popup.mp3";

const ChatBot = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [messages, setMessages] = useState([]);
  const inputRef = useRef(null);
  const chatboxRef = useRef(null);
  const [firstTime, setFirstTime] = useState(true);
  const [chatbotOpened, setChatbotOpened] = useState(false);
  // Updated JSON data with more options
  const jsonData = {
    options: {
      "I want to make business with you": {
        message: "Great! Please choose one of the following services:",
        options: ["Web Development", "Graphic Design", "Marketing Strategy"],
      },
      "I want to subscribe to Imaggar": {
        message:
          "Awesome! To subscribe, please fill out the subscription form.",
        options: [
          "Fill out subscription form",
          "Ask a question about subscription",
        ],
      },
      "I want to contact you": {
        message: "Sure! Here are the contact details:",
        options: ["Call us", "Email us", "Visit our office"],
      },
    },
  };

  useEffect(() => {
    if (showChatbot) {
      inputRef.current.focus();
      if (firstTime) {
        setTimeout(() => {
          handleBotMessage(
            "Hi there! I am S.A.M (Sophisticated Assistive Mechanism). How can I assist you today?"
          );
          playPopSound();
        }, 2000);

        setTimeout(() => {
          handleBotMessage(
            "Please choose an option:",
            Object.keys(jsonData.options)
          );
          setFirstTime(false);
        }, 4000);
      }
    }
  }, [showChatbot, firstTime, jsonData.options]);

  const toggleChatbot = () => {
    setShowChatbot((prev) => !prev);
    setChatbotOpened((prev) => !prev);
  };

  const handleUserMessage = (message) => {
    if (message.trim() === "") {
      return;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, type: "user" },
    ]);

    setTimeout(() => {
      handleBotMessage("I am a Beta Version Bot");
      playPopSound();
    }, 1000);

    inputRef.current.value = "";
  };

  const handleBotMessage = (message, options) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, type: "bot", options },
    ]);
  };

  const playPopSound = () => {
    const audio = new Audio(popSound);
    audio.play();
  };

  const handleOptionClick = (option) => {
    const nextMessage = jsonData.options[option]?.message;
    const nextOptions = jsonData.options[option]?.options;

    if (nextMessage) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: option, type: "user" },
        { text: nextMessage, type: "bot", options: nextOptions },
      ]);
    }
  };

  const isMobileView = window.innerWidth <= 767;

  useEffect(() => {
    // Scroll to the bottom of the chatbox container
    if (chatboxRef.current && (messages.length > 0 || chatbotOpened)) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [messages, chatbotOpened]);

  return (
    <div
      className={`chatbot-container ${showChatbot ? "show-chatbot" : ""} ${
        isMobileView ? "mobile-view" : ""
      }`}
      onClick={() => {
        // When the chatbot container is clicked, close it
        if (isMobileView && showChatbot) {
          toggleChatbot();
        }
      }}
    >
      {showChatbot && (
        <div className="chatbot">
          <div className="chat-header">
            <div className="chatbot-logo-container">
              <img
                src={ChatBotLogo}
                alt="Chatbot Logo"
                className="chatbot-logo"
              />
            </div>
            <h2>Chatbot</h2>
            {isMobileView ? (
              <button
                className="mob-chatbot-close-icon"
                onClick={toggleChatbot}
              >
                <FaTimes />
              </button>
            ) : null}
          </div>
          <div ref={chatboxRef} className="chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.type}`}>
                {msg.text}
                {msg.options && (
                  <div className="options">
                    {msg.options.map((option, optionIndex) => (
                      <button
                        key={optionIndex}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input
              ref={inputRef}
              type="text"
              placeholder="Type your message..."
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleUserMessage(e.target.value);
                }
              }}
            />
            <button
              onClick={() =>
                inputRef.current && handleUserMessage(inputRef.current.value)
              }
            >
              Send
            </button>
          </div>
        </div>
      )}
      {showChatbot && !isMobileView ? (
        <button className="chatbot-close-icon" onClick={toggleChatbot}>
          <FaTimes />
        </button>
      ) : (
        <button className="chatbot-icon" onClick={toggleChatbot}>
          <img src={ChatBotIcon} alt="Chatbot" />
        </button>
      )}
    </div>
  );
};

export default ChatBot;
