import React from "react";
import Landing from "../../components/Home/homeLanding";
import Trusted from "../../components/Home/trusted";
import Service from "../../components/Home/service";
import Counter from "../../components/Home/counter";

// import Testimonials from '../../components/Home/testimonials'
import ContactBar from "../../components/Home/ContactBar";
function Home() {
  return (
    <div>
      <Landing />
      {/* <Trusted /> */}
      <Service />

      <Counter />

      {/* <Testimonials /> */}
      <ContactBar />
    </div>
  );
}

export default Home;
